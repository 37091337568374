<template>
  <st-section :header="$t('APPLICATION_ATTACHMENTS.TITLE')">
    <template #body>
      <div class="st-files-container">
        <template v-if="documents.length">
          <div
            v-for="(doc, index) in documents"
            :key="index"
            class="st-file-item"
          >
            <div class="text-truncate">{{ doc.file.name }}</div>
            <div class="st-file-item-actions">
              <i
                class="st-remove-file fas fa-trash mr-4"
                v-if="canDelete(doc)"
                @click="removeFile(index)"
              ></i>
              <i
                class="st-view-file fas fa-eye"
                @click="viewFile(doc)"
              ></i>
            </div>
          </div>
        </template>
        <div v-if="!canUpload && !documents.length">
          {{ $t("COMPLAIN.FORM.EMPTY_DOCUMENT_LABEL") }}
        </div>
      </div>
      <div v-if="canUpload">
        <b-form-file
          v-show="false"
          @change="uploadFile"
          id="file-default"
          accept=".jpg, .jpeg, .png, .pdf, .zip"
          ref="upload_documents"
        ></b-form-file>
        <st-button
          v-if="uploadedFilesLimit"
          variant="primary"
          customClass="st-btn-upload"
          class="px-6 mt-4"
          :callback="pickDocuments"
          >{{ $t("COMPLAIN.FORM.UPLOAD_DOCUMENT_BUTTON") }}
        </st-button>
      </div>
    </template>
  </st-section>
</template>
<script>
import { mapGetters } from 'vuex';
import Notify from "@/shared/utils/notify";
import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';

// TODO: Move to settings
const MAX_APPLICATION_ATTACHMENTS = 20;
const MAX_FILE_SIZE = 50; // MB
const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/zip",
];

export default {
  name: "ApplicationAttachments",
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: [],
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      record: "applications/form/record",
      fileToken: "applications/form/fileToken",
      currentUser: 'auth/currentUser',
    }),
    uploadedFilesLimit() {
      return this.documents.length <= MAX_APPLICATION_ATTACHMENTS;
    },
    hasPermissionToOrganisationsApplication() {
      return new ApplicationsPermissions(this.currentUser).readStaff;
    },
    hasPermissionToStaffOwnApplications() {
      return new ApplicationsPermissions(this.currentUser).readOwnStaff;
    },
    hasPermissionToReadStaff() {
      return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
    },
    canUpload() {
      if (this.hasPermissionToReadStaff ||
        (this.viewMode && this.record?.status !== 'review')
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    canDelete(doc) {
      if (this.hasPermissionToReadStaff) return false;
      if (this.viewMode && this.record?.status !== 'review') return false;
      if (doc.file.file_id && this.record?.status === 'review') return false;
      return true;
    },
    pickDocuments() {
      this.$refs.upload_documents.$el.childNodes[0].click();
    },
    uploadFile(ev) {
      const uploaded = ev.target.files[0];
      if (!this.validate(uploaded)) {
        this.clearFile(ev);
        Notify("error", this.errorMessage);
        return false;
      }
      const url = URL.createObjectURL(uploaded);
      const fileName = this.getUniqueFileName(uploaded.name);
      const newFile = { url, file: new File([uploaded], fileName, { type: uploaded.type }) };
      this.documents = [...this.documents, newFile];
      this.clearFile(ev);
      this.emitChangeDocument();
    },
    /**
     * If the file name exists then increment file name by one
     * eg. filename (1).png
     */
    getUniqueFileName(fileName, index = 0) {
      let checkName = fileName;
      let ext = "";
      if (index) {
        if (checkName.indexOf(".") > -1) {
          let tokens = checkName.split(".");
          ext = "." + tokens.pop();
          checkName = tokens.join(".");
        }

        checkName = `${checkName} (${index})${ext}`;
      }

      const nameExists =
        this.documents.filter((el) => el.name === checkName).length > 0;
      return nameExists
        ? this.getUniqueFileName(fileName, index + 1)
        : checkName;
    },
    clearFile(ev) {
      ev.target.value = null;
    },
    validate(file) {
      if (!this.validateFilesLength()) return false;
      if (!this.validateFileSize(file)) return false;
      if (!this.validateFileType(file)) return false;
      return true;
    },
    validateFilesLength() {
      const totalFiles = this.documents.length + 1;
      if (totalFiles <= MAX_APPLICATION_ATTACHMENTS) return true;
      this.errorMessage = this.$t("VALIDATION.MAX_UPLOADED_FILES", {
        max: MAX_APPLICATION_ATTACHMENTS,
      });
      return false;
    },
    validateFileSize(file) {
      const fileSize = file.size / 1024 / 1024; // MB
      if (fileSize <= MAX_FILE_SIZE) return true;
      this.errorMessage = this.$t("VALIDATION.UPLOADED_FILE_SIZE");
      return false;
    },
    validateFileType(file) {
      if (ALLOWED_FILE_TYPES.includes(file.type)) return true;
      this.errorMessage = this.$t("VALIDATION.UPLOADED_FILE_TYPE");
      return false;
    },
    removeFile(index) {
      this.$delete(this.documents, index);
      this.emitChangeDocument();
    },
    viewFile(doc) {
      const file_id = doc.file.file_id;
      const url = file_id ?
        `${window.VUE_APP_API_URL}/files/${file_id}?token=${this.fileToken}` : `${doc.url}`;
      window.open(url, '_blank');
    },
    emitChangeDocument() {
      const mappedDocuments = this.documents.map(doc => doc.file);
      this.$emit('changeList', mappedDocuments, 'attachments');
    },
    initDocuments() {
      this.documents = [];
      if (this.$route.params.id) {
        const identificationFiles = this.record?.identification_files?.length ? this.record.identification_files : [];
        this.documents = identificationFiles.map((file) => ({
          file: {
            ...file,
            name: file.filename,
          },
        }));
      }
    },
  },
  created() {
    this.initDocuments();
  },
  watch: {
    record: function() {
      this.initDocuments();
    }
  }
};
</script>
<style lang="">
</style>
